import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import ElementUI from 'element-ui'
import wow from 'wowjs'
import VueParticles from 'vue-particles'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import BaiduMap from 'vue-baidu-map'

import 'element-ui/lib/theme-chalk/index.css'
import 'remixicon/fonts/remixicon.css'
import 'animate.css'
import 'wowjs/css/libs/animate.css'
import 'swiper/dist/css/swiper.css'
import '@/style/fonts.css'
import '@/style/style.less'
import '@/style/about.less'
import '@/style/solution.less'
import '@/style/product.less'
import '@/style/resource.less'
// import '@/assets/iconfont/iconfont.css'
// import '@/style/main.css'
Vue.prototype.$wow = wow

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueParticles)
Vue.use(VueAwesomeSwiper)
Vue.use(BaiduMap, {
  ak:'erAZWb0agSPbDfHhZfTY1vgXkBF4hpPe'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
