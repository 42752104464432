<template>
  <router-view class="all-container"/>
</template>

<script>
export default {
  name: "App",
  updated() {
    if (
      localStorage.scrollPosition &&
      localStorage.routePath === this.$route.path
    ) {
        if (this.$route.path !== '/' && this.$route.path !== '/index') {
          // 从localStorage中获取scrollPosition的值
          document.documentElement.scrollTop = parseInt(localStorage.scrollPosition);
        }
    } else {
        document.documentElement.scrollTop = 0;
    }
    localStorage.routePath = this.$route.path;
    // 重置顶部导航栏的遮罩状态，否则跳转到新页面后无法滚动
    document.body.style.overflow = 'auto'
    // 监听scroll事件，更新scrollPosition的值
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      localStorage.scrollPosition = document.documentElement.scrollTop;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
