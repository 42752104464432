import Vue from 'vue'
import Router from 'vue-router'

// 避免在点击同一个路由操作的时候报错
const includePush = Router.prototype.push
Router.prototype.push = function push(location) {
	return includePush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('@/pages/index/index')
		},
		{
			path: '/solution/electricalArchitecture',
			name: 'architecture',
			component: () => import('@/pages/solution/architecture/architecture')
		},
		{
			path: '/solution/preevision',
			name: 'preevision',
			component: () => import('@/pages/solution/architecture/preevision')
		},
		{
			path: '/solution/preevision2x',
			name: 'preevision2x',
			component: () => import('@/pages/solution/architecture/preevision2x')
		},
		{
			path: '/solution/controllerSoftware',
			name: 'controllerSoftware',
			component: () => import('@/pages/solution/embedSoftware/controllerSoftware')
		},
		{
			path: '/solution/gateway',
			name: 'gateway',
			component: () => import('@/pages/solution/embedSoftware/gateway')
		},
		{
			path: '/solution/automotiveNetwork',
			name: 'network',
			component: () => import('@/pages/solution/networkTest/network')
		},
		{
			path: '/solution/vehicleEthernet',
			name: 'ethernet',
			component: () => import('@/pages/solution/networkTest/ethernet')
		},
		{
			path: '/solution/adas',
			name: 'Drive',
			component: () => import('@/pages/solution/simulationTest/Drive')
		},
		{
			path: '/solution/electronicControl',
			name: 'ElectronicControl',
			component: () => import('@/pages/solution/simulationTest/ElectronicControl')
		},
		{
			path: '/solution/cockpit',
			name: 'Cockpit',
			component: () => import('@/pages/solution/simulationTest/Cockpit')
		},
		{
			path: '/solution/networkConnection',
			name: 'NetworkConnection',
			component: () => import('@/pages/solution/simulationTest/NetworkConnection')
		},
		{
			path: '/solution/vehicleTest',
			name: 'vehicleTest',
			component: () => import('@/pages/solution/vehicleTest/vehicleTest')
		},
		{
			path: '/solution/securityTest',
			name: 'securityTest',
			component: () => import('@/pages/solution/securityTest/securityTest')
		},
		{
			path: '/solution/toolSoftware',
			name: 'toolSoftware',
			component: () => import('@/pages/solution/applicationSoftware/toolSoftware')
		},
		{
			path: '/solution/platformSoftware',
			name: 'platformSoftware',
			component: () => import('@/pages/solution/applicationSoftware/platformSoftware')
		},
		{
			path: '/solution/adasData',
			name: 'adas',
			component: () => import('@/pages/solution/applicationSoftware/adas')
		},
		{
			path: '/product/vectorSoftware',
			name: 'vectorSoftware',
			component: () => import('@/pages/product/vector/vectorSoftware')
		},
		{
			path: '/product/vectorHardware',
			name: 'vectorHardware',
			component: () => import('@/pages/product/vector/vectorHardware')
		},
		{
			path: '/product/DYNA4',
			name: 'DYNA',
			component: () => import('@/pages/product/vector/DYNA')
		},
		{
			path: '/product/vectorCast',
			name: 'vectorCast',
			component: () => import('@/pages/product/vector/vectorCast')
		},
		{
			path: '/product/vp67',
			name: 'vp67',
			component: () => import('@/pages/product/vector/vp67')
		},
		{
			path: '/product/VTD',
			name: 'vtd',
			component: () => import('@/pages/product/VTD')
		},
		{
			path: '/product/Qt',
			name: 'qt',
			component: () => import('@/pages/product/Qt')
		},
		{
			path: '/product/RTaW',
			name: 'RTaW',
			component: () => import('@/pages/product/RTaW')
		},
		{
			path: '/product/NXP',
			name: 'NXP',
			component: () => import('@/pages/product/NXP')
		},
		{
			path: '/resource',
			name: 'resource',
			component: () => import('@/pages/resource/index')
		},
		{
			path: '/about',
			name: 'about',
			component: () => import('@/pages/about/index')
		},
		{
			path: '/joinUs',
			name: 'joinUs',
			component: () => import('@/pages/about/joinUs')
		},
		{
			path: '/contact',
			name: 'contactUs',
			component: () => import('@/pages/about/contactUs')
		},
		{
			path: '/solution/Vehicle',
			name: 'vehicle',
			component: () => import('@/pages/solution/vehiclecontroller/Vehicle')
		},
		{
			path: '/solution/hmi',
			name: 'vehicle',
			component: () => import('@/pages/solution/architecture/hmi')
		},
		{
			path: '/solution/controllerSoftwareDevelopment',
			name: 'vehicle',
			component: () => import('@/pages/solution/embedSoftware/controllerSoftwareDevelopment')
		},
		{
			path: '/solution/controllerSoftwareEmbedded',
			name: 'vehicle',
			component: () => import('@/pages/solution/embedSoftware/controllerSoftwareEmbedded')
		},
		{
			path: '/solution/V2X',
			name: 'vehicle',
			component: () => import('@/pages/solution/simulationTest/V2X')
		},
	]
})